<template>

    <div v-show="false" id="divAsPdf">
        <h1 style="text-align: center;">Récapitulatif de <span style="color: red">{{ nickname }}</span></h1>
        <h3 style="text-align: center; font-size: 20px;">Tu as {{ age }} ans</h3>
        <h3 style="text-align: center; font-size: 20px;" v-if="entreprise">Tu es en entreprise</h3>
        <hr>

        <div v-for="(category, index) in categories" :key="index">
            <h2 style="text-align: center; font-size: 25px">Catégorie : {{category.label}}</h2>
            <div v-for="(question, iq) in categories[index].questions" :key="iq">
                <p v-if="question.age < this.user.age">{{question.label}} : {{ question.choices[a[index][0][question.id]] }}</p>
            </div>
            <p>Détails : {{ a[index][1] }}</p>
        </div>
    </div>

</template>

<script>

    // ** PDFMAKE doc. : https://pdfmake.github.io/docs/0.1/getting-started/
    // ** HTML-TO-PDF doc. : https://github.com/Aymkdn/html-to-pdfmake

    export default {
        name: "Pdfmaker",
        mounted() {
            console.log(this.requestQ)
        },
        data() {
            return {
                nickname: this.user.getStorage("qcm-user")._name,
                age: this.user.getStorage("qcm-user")._age,
                entreprise: this.user.enterprise,
                a: this.user.getStorage("qcm-user")._answers,
                categories: this.requestQ,
            }
        },
        methods: {}
    }

</script>

<style scoped>

</style>