<template>

    <div id="settings">
        <div class="center-content">
            <div class="container">
                <div class="block100">
                    <img src="../assets/img/focus_settings.svg" alt="">
                    <h1>Réglages</h1>
                    <p>Choisis une écriture</p>
                    <ul>
                        <li>
                            <button role="button"
                                    aria-label="Choisir l'écriture Stylo"
                                    @click="fontStylo"
                                    class="btn stylo">
                                Stylo
                            </button>
                        </li>
                        <li>
                            <button role="button"
                                    aria-label="Choisir l'écriture Jeux vidéo"
                                    @click="fontGamer"
                                    class="btn game">
                                Jeux
                            </button>
                        </li>
                        <li>
                            <button role="button" aria-label="Choisir l'écriture adaptée aux personnes malvoyantes" @click="fontClassic" class="btn classic">Luciole</button>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="container">
                <div class="block100">
                    <p>Choisis la taille des textes</p>
                    <ul>
                        <li>
                            <button role="button"
                                    aria-label="Écriture en taille normal"
                                    @click="fontNormal"
                                    class="btn">
                                Petits
                            </button>
                        </li>
                        <li>
                            <button role="button"
                                    aria-label="Agrandir les écritures"
                                    @click="fontHigh"
                                    class="btn">
                                GRANDS
                            </button>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="container">
                <div class="block100">
                    <ul>
                        <li v-if="exist === 'DoNotExist'">
                            <router-link to="/login" class="btn" aria-label="Valider mes choix">Valider</router-link>
                        </li>
                        <li v-else>
                            <router-link to="/categories" class="btn" aria-label="Valider mes choix">Valider</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    const root = document.querySelector(':root');

    export default {
        name: "Reglages",
        data() {
            return {
                theme: '',
                font: 'luciole',
                size: 'normal',
                exist: ''
            }
        },
        mounted() {
            if (localStorage.getItem('qcm-user') !== null) {
                this.exist = "Exist";
            } else {
                this.exist = "DoNotExist";
            }
        },
        methods: {
            // ** Font
            fontStylo: function()
            {
                root.style.setProperty('--ff', '\'Rancho\', cursive');
            },
            fontGamer: function()
            {
                root.style.setProperty('--ff', '\'Teko\', sans-serif');
            },
            fontClassic: function()
            {
                root.style.setProperty('--ff', '\'Luciole\', sans-serif');
            },

            // ** Size
            fontNormal: function()
            {
                root.style.setProperty('--fs', '1.2em');
            },
            fontHigh: function()
            {
                root.style.setProperty('--fs', '1.45em');
            },
        }
    }
</script>

<style scoped>
    h1 {
        margin-top: 0;
    }
    #settings {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
        background: #FDD0AD url("../assets/img/fulltown_background.svg") no-repeat bottom;
        background-size: contain;
        padding-bottom: 20px;
    }
    #settings .container:last-child ul:last-child {
        margin-top: 25px;
    }
    #settings p {
        margin: 30px 0 5px 0;
    }
    .stylo {
        font-family: 'Rancho', cursive;
    }
    .game {
        font-family: 'Teko', sans-serif;
    }
    .classic {
        font-family: 'Luciole', sans-serif;
    }
</style>