<template>
    <section>
        <div class="container-fluid">
            <img :src="require(`@/assets/img/${isPicture}`)" :alt="isPictureAlt">
            <div class="btndown">
                <button aria-label="Bouton descendre jusqu'au questionnaire" class="btn" @click="godown">
                    <svg width="49" height="64" viewBox="0 0 49 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.29182 -2.69779e-05L33.6418 -2.81734e-05L43.2801 -2.85947e-05L35.8444 35.2048L49 35.2048L22.6887 63.1111L-1.21982e-06 35.2048L11.0584 35.2048L6.29182 -2.69779e-05Z"/>
                    </svg>
                </button>
            </div>
        </div>

        <div class="container" id="start">
            <div class="block100">
                <h1>
                    {{ items.label }}
                </h1>
                <p>
                    {{ items.description }}
                </p>
            </div>
        </div>

        <div v-for="(question, index) in items.questions" :key="index">
            <div class="container" v-if="question.age <= this.user.age">
                <div class="block100">
                    <div role="radiogroup" class="questions">
                        <!-- Question -->
                        <p>{{ question.label }}</p>

                        <ul>
                            <!-- Choices 1 -->
                            <li v-for="(choice, i) in question.choices" :key="i">
                                <input role="radio"
                                       :id="'choice'+index+i"
                                       :data-qid="question.id"
                                       type="radio"
                                       :value="i"
                                       :aria-labelledby="'choice'+index+i"
                                       :onclick="handleChoices"
                                       :aria-checked="isChecked(question.id, i)"
                                       :checked="isChecked(question.id, i)"
                                >
                                <label :for="'choice'+index+i">{{ choice }}</label>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="container" id="textarea">
            <div class="block75">
                <label for="details">{{ items.details }}</label>
                <textarea name="details"
                          id="details"
                          cols="30"
                          rows="10"
                          v-model="this.user.answers[category][1]">
                </textarea>
            </div>
        </div>

        <div class="container">
            <div class="block100">
                <ul>
                    <li>
                        <router-link :to="'/' + isPrev"
                                     @click="validate(responses, details)"
                                     class="btn"
                                     aria-label="Bouton revenir à la catégorie précédente">Précédent
                        </router-link>
                    </li>
                    <li v-if="isRoad == '/health' && this.user.age < 16 || isRoad == '/right' && this.user.enterprise === false || isRoad == '/work'">
                        <router-link to="/results"
                                     @click="validate(responses, details)"
                                     class="btn"
                                     aria-label="Bouton valider mes réponses et aller aux résultats">Suivant
                        </router-link>
                    </li>
                    <li v-else>
                        <router-link :to="'/' + isNext"
                                     @click="validate(responses, details)"
                                     class="btn"
                                     aria-label="Bouton valider mes réponses et passer à la catégorie suivante">Suivant
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </section>

</template>

<script>
    export default {

        name: "Category",
        data() {
            return {
                category: this.isCategory,
                items: this.requestQ[this.isCategory],
                isRoad: this.$route.fullPath
            }
        },
        props: {
            isCategory: String,
            isNext: String,
            isPrev: String,
            isPicture: String,
            isPictureAlt: String
        },
        methods: {
            handleChoices: function (e) {
                const qid = e.target.dataset.qid;
                document.querySelectorAll('input[type="radio"][data-qid="' + qid + '"]').forEach(i => {
                    if (i !== e.target) i.checked = false;
                });
            },
            isChecked: function (qid, choiceIndex) {
                if (typeof this.user.answers[this.category][0][qid] === "undefined") return false;
                else return this.user.answers[this.category][0][qid] === choiceIndex.toString();
            },
            validate: function () {
                let answers = this.user.answers;
                document.querySelectorAll('input[type="radio"]:checked').forEach(i => {
                    answers[this.category][0][i.dataset.qid] = i.value;
                });
                this.user.setAnswers(answers);
                this.user.setStorage("qcm-user", this.user);
            },
            godown: function () {
                document.getElementById("start").scrollIntoView({ behavior: "smooth" });
            }
        }
    }
</script>

<style scoped>
    h1 {
        margin-top: -35px;
        font-family: "Rancho", sans-serif;
        font-size: clamp(70px, 10vw, 200px);
        color: var(--mc);
        margin-bottom: 20px;
        line-height: 1em;
    }

    .container-fluid {
        display: flex;
        align-items: center;
        width: 100vw;
        height: 100vh;
        margin-top: 35px;
        background-color: #FFD0AD;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .container:not(section:first-child) {
        position: relative;
        background-color: #FFCC8A;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    #textarea {
        padding-top: 30px;
    }

    #textarea label {
        display: block;
        padding-bottom: 20px;
    }

    textarea {
        width: 100%;
    }

    .questions {
        padding: 20px 0;
    }

    .questions p {
        padding: 24px 42px;
        background-color: var(--mc);
        color: white;
        border-radius: 25px;
        border: 4px solid #ac2c2c;
    }

    .btn {
        margin: 40px 0 80px 0;
    }

    .questions ul li {
        color: var(--mc);
        display: block;
        position: relative;
        float: left;
        width: 100%;
    }

    ul li input[type=radio] {
        position: absolute;
        visibility: hidden;
    }

    ul li label {
        display: block;
        position: relative;
        padding: 15px;
        margin: 10px;
    }

    input[type=radio]:checked ~ label {
        background-color: var(--sc);
        color: var(--fc);
        box-shadow: 4px 6px var(--fc);
        border: 3px solid #c3bf1c;
    }

    .btndown {
        position: absolute;
    }

    .btndown .btn {
        border-radius: 50%;
        display: flex;
        align-items: center;
        animation: move;
        animation-iteration-count: infinite;
        animation-duration: 2s;
        animation-direction: alternate;
        -webkit-animation: move;
        -webkit-animation-direction: alternate;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-duration: 2s;
    }

    .btndown .btn svg {
        fill: var(--wc);
        transition: all .3s ease-in-out;
    }

    .btndown .btn:hover svg {
        fill: darkslategrey;
    }

    @-webkit-keyframes move {
        from {
            transform: translate(0, -10px);
        }
        to {
            transform: translate(0, 10px);
        }
    }

    @media (max-width: 720px) {
        .questions ul {
            flex-flow: column wrap;
        }
    }
</style>