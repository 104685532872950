<template>

    <Nav></Nav>

    <router-view v-slot="{ Component, route }">
        <transition name="slide" class="wrapper">
            <component :is="Component" :key="route.path"/>
        </transition>
    </router-view>

    <Footer></Footer>

</template>

<script>
    import Nav from './components/Nav';
    import Footer from './components/Footer';

    export default {
        name: 'App',
        components: {
            Nav,
            Footer,
        },
    }
</script>

<style scoped>
    .wrapper {
        width: 100%;
        min-height: 100vh;
    }
    .slide-leave-active {
        z-index: 10;
    }

    .slide-enter-active,
    .slide-leave-active {
        transition: all 0.75s ease-out;
    }

    .slide-enter-to {
        position: absolute;
        right: 0;
    }

    .slide-enter-from {
        position: absolute;
        right: -100%;
    }

    .slide-leave-to {
        position: absolute;
        left: -100%;
    }

    .slide-leave-from {
        position: absolute;
        left: 0;
    }
</style>