<template>

    <div id="select">
        <div class="center-content">
            <div class="container">
                <div class="block75">
                    <img src="../assets/img/focus_menu.svg" alt="Logo menu">
                    <h1>Menu</h1>
                    <p>
                        Retrouve ici la synthèse de tes réponses. Cela fait apparaitre les domaines dans lesquels
                        tu te sens autonome et ceux pour lesquels tu pourrais demander de l’aide. Tu peux cliquer
                        sur un domaine pour y accéder directement !
                    </p>
                </div>
            </div>

            <div class="container">
                <div class="block100">
                    <ul class="justify-content">
                        <li>
                            <router-link to="/dream" class="btn" aria-label="Bouton se rendre à la catégorie : Mes rêves">
                                {{ this.requestQ.dream.label }}
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/selfdone" class="btn" aria-label="Bouton se rendre à la catégorie : Mon autonomie">
                                {{ this.requestQ.selfdone.label }}
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/social" class="btn" aria-label="Bouton se rendre à la catégorie : Ma vie sociale">
                                {{ this.requestQ.social.label }}
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/school" class="btn" aria-label="Bouton se rendre à la catégorie : Ma scolarité">
                                {{ this.requestQ.school.label }}
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/health" class="btn" aria-label="Bouton se rendre à la catégorie : Ma santé">
                                {{ this.requestQ.health.label }}
                            </router-link>
                        </li>
                        <li v-if="this.user.age >= 16">
                            <router-link to="/right" class="btn" aria-label="Bouton se rendre à la catégorie : Mes droits">
                                {{ this.requestQ.right.label }}
                            </router-link>
                        </li>
                        <li v-if="this.user.enterprise === true">
                            <router-link to="/work" class="btn" aria-label="Bouton se rendre à la catégorie : Ma vie professionnelle">
                                {{ this.requestQ.work.label }}
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="container">
                <div class="block100">
                    <ul class="justify-content">
                        <li>
                            <button role="button" aria-label="Bouton revenir à la page précédente" @click="this.$router.go(-1)">Retour</button>
                        </li>
                        <li>
                            <router-link to="/results" class="btn" aria-label="Bouton accéder aux résultats">Résultats</router-link>
                        </li>
                        <li>
                            <router-link to="/reset" class="btn" aria-label="Bouton effacer toutes mes réponses et recommencer">Reset</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    </div>

</template>

<script>

    export default {
        name: "Select",
        data() {
            return this.score();
        },
        methods: {
            score: function() {
                let categories = this.requestQ;
                let scores     = {};

                for(const [i] of Object.entries(categories))
                {
                    let totalq = 0, totalr = 0;

                    categories[i].questions.forEach((q) => {
                        if(this.user.age >= q.age)
                        {
                            totalq ++;

                            if(typeof this.user.answers[i][0][q.id] !== "undefined")
                            {
                                totalr += parseInt(this.user.answers[i][0][q.id]);
                            }
                        }
                    });

                    scores[i+"v"] = totalr !== 0 ? Math.round(totalr * 100 / (totalq * 3)) : 0;
                }

                return scores;
            },
        }
    }

</script>

<style scoped>
    h1 {
        margin-top: 0;
    }

    #select {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
        background: #FDD0AD url("../assets/img/fulltown_background.svg") no-repeat bottom;
        background-size: contain;
    }

    #select .container:not(.container:last-child) ul {
        display: flex;
        flex-flow: column wrap;
    }

    #select .container:nth-child(2) ul > li {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        flex-basis: 0;
        text-align: center;
        width: 100%;
        padding: 10px;
    }

    #select .container:nth-child(2) ul li a {
        width: clamp(50px, 80%, 250px)
    }

    #select .container:last-child {
        padding-top: 30px;
        text-align: center;
    }

    @media(max-width: 960px) {
        #select .container:nth-child(2) ul > li {
            flex-flow: column wrap;
        }
    }
</style>