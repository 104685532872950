<template>
    <section>
        <div class="center-content">
            <div class="container">
                <div class="block100">
                    <h1>Bienvenue {{ this.nickname }}</h1>
                    <label for="nickname">Choisis un pseudo</label>
                    <input type="text" id="nickname" v-model="nickname" maxlength="8">
                </div>
            </div>

            <div class="container">
                <div class="block100">
                    <label for="age">Quel âge as-tu ? (13+)</label>
                    <input id="age" type="number" min="13" max="40" step="1" v-model="age"/>
                </div>
            </div>

            <div class="container" v-if="this.age >= 16">
                <div class="block100">

                    <!-- A MODIFIER, NE FONCTIONNE PAS CORRECTEMENT -->

                    <div class="checkbox">
                        <label for="enterprise">Coches la case si tu es<br> en CAP, Bac PRO, alternance...</label>
                        <input type="checkbox" id="enterprise" aria-checked="false" name="enterprise"
                               v-model="enterprise" @click="toggleAriaChecked()">
                    </div>

                </div>
            </div>

            <ul>
                <li v-if="nickname && age >= 13">
                    <router-link to="/categories" @click="validate" class="btn" aria-label="Valider mes choix">
                        Valider
                    </router-link>
                </li>
                <li v-else>
                    <button role="button" aria-label="N'oublies pas de remplir les champs" class="btn btn-deactivate">
                        En attente
                    </button>
                </li>
            </ul>
        </div>
    </section>
</template>

<script>

    export default {

        name: "Home",
        data() {
            return {
                nickname: '',
                age: '',
                enterprise: false
            }
        },
        methods: {
            validate: function () {
                //Set values to object
                this.user.setName(this.nickname);
                this.user.setAge(this.age);
                this.user.setEnterprise(this.enterprise);

                //push values of object to localstorage
                this.user.setStorage("qcm-user", this.user);
            },
            toggleAriaChecked: function () {
                let t = document.getElementById("enterprise");
                if (t.getAttribute("aria-checked") === "false") {
                    t.setAttribute("aria-checked", "true");
                } else {
                    t.setAttribute("aria-checked", "false")
                }
                t.focus();
            }
        }
    }
</script>

<style scoped>
    h1 {
        margin-top: 0;
    }

    section {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
        background: #FDD0AD url("../assets/img/fulltown_background.svg") no-repeat bottom;
        background-size: contain;
    }

    label, input {
        display: block;
    }

    label {
        margin: 30px 0 5px 0;
    }

    input {
        width: 100%;
        padding: 5px;
        text-align: center;
    }

    ul > li {
        margin-top: 25px;
    }

    input[type="checkbox" i] {
        box-sizing: content-box;
        height: 40px;
        background-color: red;
    }

</style>