<template>
    <nav>
        <ul class="menu" id="nav">
            <li v-if="btn_return">
                <router-link to="/categories" aria-label="Retourner à l'accueil" class="btn-return"></router-link>
            </li>
            <li v-if="btn_menu">
                <router-link to="/menu" aria-label="Aller au menu et voir mes statistiques" class="btn-select"></router-link>
            </li>
            <li>
                <router-link to="/settings" aria-label="Aller aux réglages" class="btn-settings"></router-link>
            </li>
        </ul>
    </nav>
</template>

<script>
    export default {
        name: "Menu",
        data() {
            return {
                btn_return: true,
                btn_menu: true
            }
        },
        watch: {
            '$route': {
                handler: function (search) {
                    if(search.fullPath === "/" || search.fullPath === "/login" || search.fullPath === "/settings") {
                        this.btn_return = false;
                        this.btn_menu = false;
                    } else if(search.fullPath === "/categories") {
                        this.btn_return = false;
                        this.btn_menu = true;
                    } else if(search.fullPath === "/menu") {
                        this.btn_return = true;
                        this.btn_menu = false;
                    } else {
                        this.btn_return = true;
                        this.btn_menu = true;
                    }
                },
                deep: true,
                immediate: true
            }
        },
        created () {
            window.addEventListener('scroll', this.handleScroll);
        },
        methods: {
            handleScroll () {
                if(window.scrollY > window.innerHeight) {
                    document.getElementById('nav').style.opacity = '0';
                    document.getElementById('nav').style.visibility = 'hidden';
                } else {
                    document.getElementById('nav').style.visibility = 'visible';
                    document.getElementById('nav').style.opacity = '1';
                }
            }
        }
    }
</script>

<style scoped>
    nav {
        position: fixed;
        top: 0;
        width: 100vw;
        height: 35px;
        background-color: var(--dc);
        z-index: 100;
    }

    nav:after {
        content: "";
        position: fixed;
        width: 100vw;
        height: 35px;
        background-color: var(--dc);
        bottom: 0;
        left: 0;
    }

    .menu {
        position: absolute;
        margin: 45px 20px;
        right: 0;
        opacity: 1;
        transition: all 0.4s ease-in-out;
    }

    .menu ul {
        display: block;
    }

    .menu ul li {
        display: inline-block;
        opacity: 1;
    }

    .menu > li {
        display: inline;
        padding: 10px 4px;
    }

    .menu > li > a > img {
        width: 55px;
        height: 55px;
    }

    a, button {
        display: block;
        width: 67px;
        height: 67px;
        background: transparent no-repeat;
        border: 0;
        cursor: pointer;
        background-size: contain;
    }

    .btn-return {
        background-image: url('../assets/img/btn_back.svg');
    }

    .btn-select {
        background-image: url('../assets/img/btn_menu.svg');
    }

    .btn-settings {
        background-image: url('../assets/img/btn_settings.svg');
    }

</style>