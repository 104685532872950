class User {

    constructor(name, age, enterprise, answers) {
       this._name = name;
       this._age = age;
       this._enterprise = enterprise;
       this._answers = answers;
    }

    /**
     * Getting localstorage sessions
     * @param name
     * @returns {any}
     */
    getStorage(name) {
        return JSON.parse(localStorage.getItem(name))
    }

    /**
     * Method set/update localstorage sessions
     * @param localStorageName
     * @param values
     */
    setStorage(localStorageName, values) {
        localStorage.setItem(localStorageName, JSON.stringify(values));
    }

    /**
     * Method get user localstorage answers
     * @param type
     * @param number
     * @returns {string|*}
     */
    isSet(type, number) {
        if (type) {
            return type[number]

        } else {
            return ""
        }
    }

    get name() {
        return this._name;
    }

    setName(value) {
        this._name = value;
    }

    get age() {
        return this._age;
    }

    setAge(value) {
        this._age = value;
    }

    get enterprise() {
        return this._enterprise;
    }

    setEnterprise (value) {
        return this._enterprise = value;
    }

    get answers() {
        return this._answers;
    }

    setAnswers(value) {
        return this._answers = value;
    }

}

export default User;