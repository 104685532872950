<template>

    <div class="container-fluid" id="fulltown">
        <div class="container">
            <div class="block100">
                <router-link to="/dream" class="btn" aria-label="Bouton lancer le questionnaire">Lancer</router-link>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "Categories",
        data() {
            return {
                nickname: this.user.getStorage("qcm-user")._name,
                age: this.user.getStorage("qcm-user")._age,
                answers: this.user.getStorage("qcm-user")._answers,
            }
        }
    }
</script>

<style scoped>

    h1 {
        margin-bottom: 0;
    }

    h1 > strong {
        color: var(--mc);
    }

    #fulltown {
        display: flex;
        width: 100vw;
        height: 100vh;
        align-items: center;
        background: #FFBD67 url('../assets/img/fulltown_animated.svg') center no-repeat;
        /* animated link : ../assets/img/fulltown_animated.svg */
        background-size: cover;
    }

    #fulltown .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 120px;
        margin-bottom: 10vh;
        border-radius: 50%;
    }

    @media (max-width: 45em /*720px*/) {
        .btn {
            margin: 30px 0;
        }
    }

</style>

