<template>
    <div id="welcome">
        <div class="center-content">
            <div class="container">
                <div class="block75">
                    <h1>Reset</h1>

                    <p>
                        Tu souhaites recommencer et supprimer les réponses déjà enregistrées ?
                    </p>

                    <ul>
                        <li>
                            <router-link to="/" class="btn" aria-label="Confirmation pour effacer toutes les données" @click="reset">Oui</router-link>
                        </li>
                        <li>
                            <router-link to="/menu" class="btn" aria-label="Annuler et revenir au menu">Non</router-link>
                        </li>
                    </ul>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "Reset",
        methods: {
            reset: function() {
                localStorage.removeItem('qcm-user');
                this.user.setAnswers(
                    {
                        dream: [[], ""],
                        selfdone: [[], ""],
                        social: [[], ""],
                        school: [[], ""],
                        health: [[], ""],
                        right: [[], ""],
                        work: [[], ""]
                    }
                )
            }
        }
    }
</script>

<style scoped>
    h1 {
        margin-top: 0;
    }

    h1 > strong {
        color: #CA3433;
    }

    #welcome {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
        background-image: url("../assets/img/fulltown_background.svg");
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: contain;
        background-color: #FDD0AD;
    }
</style>