import * as VueRouter from "vue-router";
import Welcome from "../components/Welcome";
import Settings from "../components/Settings";
import Login from "../components/Login";
import Categories from "../components/Categories";
import Menu from "../components/Menu";
import Reset from "../components/Reset";
import Results from "../components/Results";

import Category from "../components/Category";

/**
 * Roads sets (Vue Router)
 * @type {*[]}
 */
const routes = [
    // ** Generals
    {path: '/', component: Welcome},
    {path: '/settings', component: Settings},
    {path: '/login', component: Login},
    {path: '/categories', component: Categories},
    {path: '/menu', component: Menu},
    {path: '/reset', component: Reset},

    // ** Categories focus
    {
        path: '/dream',
        component: Category,
        props: {
            isCategory: "dream",
            isNext: "selfdone",
            isPrev: "categories",
            isPicture: "focus_dream.svg",
            isPictureAlt: "Dessin d'un petit moulin à vent entouré d'arbres, représentant le rêve."
        }
    },
    {
        path: '/selfdone',
        component: Category,
        props: {
            isCategory: "selfdone",
            isNext: "social",
            isPrev: "dream",
            isPicture: "focus_selfdone.svg",
            isPictureAlt: "Dessin représentant le centre ville, des passages piétons et un bus dans une allée bordée d'arbres"
        }
    },
    {
        path: '/social',
        component: Category,
        props: {
            isCategory: "social",
            isNext: "school",
            isPrev: "selfdone",
            isPicture: "focus_social.svg",
            isPictureAlt: "Dessin représentant un ptit café de village. Les tables y sont sorties, des clients ont garé leur vélo à l'entrée."
        }
    },
    {
        path: '/school',
        component: Category,
        props: {
            isCategory: "school",
            isNext: "health",
            isPrev: "social",
            isPicture: "focus_school.svg",
            isPictureAlt: "Dessin représentant une petite école publique au milieux d'arbres. Des ballons sont encore dans la cours tandis que les enfants sont en classe."
        }
    },
    {
        path: '/health',
        component: Category,
        props: {
            isCategory: "health",
            isNext: "right",
            isPrev: "school",
            isPicture: "focus_health.svg",
            isPictureAlt: "Dessin représentant une petite pharmacie de quartier."
        }
    },
    {
        path: '/right',
        component: Category,
        props: {
            isCategory: "right",
            isNext: "work",
            isPrev: "health",
            isPicture: "focus_right.svg",
            isPictureAlt: "Dessin représentant un palais de justice."
        }
    },
    {
        path: '/work',
        component: Category,
        props: {
            isCategory: "work",
            isNext: "categories",
            isPrev: "right",
            isPicture: "focus_work.svg",
            isPictureAlt: "Dessin représentant une entreprise en forme de malette; des voitures y sont garés devant, les employés sont au travail."
        }
    },

    {path: '/results', component: Results},
];

const router = VueRouter.createRouter({
    routes,
    history: VueRouter.createWebHashHistory(),
    scrollBehavior(to) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
                top: 0
            }
        } else {
            return {top: 0}
        }
    }
});

export default router